//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Dialog } from "vant";
export default {
  data() {
    return {
      couponList:[],
      productCode:'',
      loading:false,
      statet:'未使用'
    };
  },
  created() {
    this.getData();
    this.currentSize = this.$route.query.code.slice(-2);
    this.productCode= this.$route.query.code 
  },
  methods: {
    getData() {
      this.$toast.loading({
        message: "查询中",
        forbidClick: true,
        duration: 0,
      });
      this.axios
        .get(
          "/" +
            this.$ajaxPrefix.consumer +
            // "/api/v1/wx/cp/storestock/getStockQuery?matnr=" +
            "/v1/wx/cp/crmcoupon/getInfoByEcid?ecid=" +
            this.$route.query.code 
        )
        .then((res) => {
          this.$toast.clear();
          if (res.data.code == 0) {
          var temp = res.data.data
          temp.forEach(item => {
            item.start_date = item.start_date.slice(0, 4) + '.' + item.start_date.slice(4, 6) + '.' + item.start_date.slice(6, 8);
            item.end_date = item.end_date.slice(0, 4) + '.' + item.end_date.slice(4, 6) + '.' + item.end_date.slice(6, 8);
            if (item.e_type.indexOf('ZK') > -1) {
              item.flag = '折'
              item.amount = parseFloat(item.balance_point);
            } else {
              item.flag = '￥'
         
              item.amount = parseInt(item.balance_point);
            }
            item.m_cost = parseFloat(item.m_cost);
            if (item.m_cost === 0) {
              item.limit = '无门槛'
            } else {
              item.limit = '满' + item.m_cost + '元使用'
            }
            item.point = parseFloat(item.point);
          });
           this.couponList=temp

          if(temp.length > 0 ){
            this.statet = temp[0].statet
          }else{
            this.statet = '没有查到优惠券'
          } 

          } else {
             this.statet = '没有查到优惠券'
            // this.$toast({ message: "没有查到库存信息", duration: 2000 });
          }

        });
    },
        doVerifCoupon() {
          this.loading=true;
          this.$toast.loading({
            message: "查询中",
            forbidClick: true,
            duration: 0,
          });
          this.axios
            .get(
              "/" +
                this.$ajaxPrefix.consumer +
                "/v1/wx/cp/crmcoupon/destroy?ecid=" +
                this.productCode
            )
            .then((res) => {
                this.loading=false;
              this.$toast.clear();
              console.log(res);
              if (res.data.code == 0) {
                this.statet = "已核销"
                // alert("成功");
                this.$toast({ message: '核销成功', duration: 2000 });
                this.$route.go(-1);
              } else {
                this.$toast({ message: res.data.msg, duration: 2000 });
              }
            });
        },

  },
  mounted() {
    document.title = "卡券核销";
  },
};
